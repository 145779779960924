import 'cross-fetch/polyfill';
import '@yourcoach/shared/utils/reset.d';
import 'intersection-observer';

import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import {ThemeProvider} from '@yourcoach/shared/styles';

import App from '@src/App';
import {Provider as AppContextProvider} from '@src/context/App';

render(
  <Router>
    <AppContextProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AppContextProvider>
  </Router>,
  document.getElementById('root'),
);
