import type {FC} from 'react';
import {memo, useEffect, useState} from 'react';
import type {ToastProps} from 'react-toast-notifications';

import {Observer} from 'mobx-react';

import {IconClose} from '../icons';

import styles from './styles.module.css';

const Toast: FC<ToastProps> = ({appearance, children, onDismiss}) => {
  const [isClose, setIsClose] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  const handleOnClose = () => {
    setIsClose(true);

    if (timeoutId === 0) {
      const timerId = +setTimeout(() => {
        onDismiss();
      }, 500);

      setTimeoutId(timerId);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <Observer>
      {() => (
        <div
          className={`Toast animate__animated animate__bounceInRight ${
            isClose ? 'animate__fadeOut' : ''
          } ${styles.Toast}`}
        >
          <div
            className={`${styles.container} ${
              appearance === 'error'
                ? styles.ToastRed
                : appearance === 'success'
                  ? styles.ToastGreen
                  : appearance === 'warning'
                    ? styles.ToastOrange
                    : ''
            }`}
          >
            <div className={styles.contentContainer}>{children}</div>
            <div className={styles.closeButContainer} onClick={handleOnClose}>
              <IconClose />
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default memo(Toast);
