import type {TypeFilterValue} from '@yourcoach/shared/modules/calendar-filter';
import type {FoodDiaryRouterDTO} from '@yourcoach/shared/modules/food-diary';
import type {MealRouterDTO} from '@yourcoach/shared/modules/food-diary/models/Meal';
import type {MealType} from '@yourcoach/shared/modules/food-diary/types';
import type {Timestamp} from '@yourcoach/shared/utils/datetime';
import type {Nullable} from '@yourcoach/shared/utils/utility-types';

import {isDefined} from '@yourcoach/shared/utils/typecheck';

import {PATH_TYPE} from '../../../routes/utils';

type SlugAndId = {id: string; slug: Nullable<string> | undefined};

type LocationState = any;

export class PathBuilderService {
  public static get PATH_TEMPLATE_TO_ASSESSMENTS() {
    return `${PATH_TYPE.yourSpace}${PATH_TYPE.myToolbox}/:assessmentId(form:[0-9a-f]{24})`;
  }

  public static get PATH_TEMPLATE_TO_COURSE() {
    return '/coaches/:slugPractice/:slugProgram/:slugCourse';
  }

  public static get PATH_TEMPLATE_TO_COURSE_GOALS() {
    return '/coaches/:slugPractice/:slugProgram/:slugCourse/goals';
  }

  // Keep in sync the parameters with "../PublicLinksService"
  public static get PATH_TEMPLATE_TO_PRACTICE() {
    return '/coaches/:slug';
  }

  // Keep in sync the parameters with "../PublicLinksService"
  public static get PATH_TEMPLATE_TO_PROGRAM() {
    return '/coaches/:slugPractice/:slugProgram';
  }

  public static toArticle(params: {articleId: string}) {
    return {
      pathname: `${PATH_TYPE.content}/${params.articleId}`,
    };
  }

  public static toAssessmentDetails(
    params: {assessmentId: string},
    state?: {
      from?: string;
    },
  ) {
    return {
      pathname: `${PATH_TYPE.yourSpace}${PATH_TYPE.myToolbox}/${params.assessmentId}`,
      state,
    };
  }

  public static toChat(chanelId?: Nullable<string>, state?: LocationState) {
    return {
      pathname: '/chats',
      search: chanelId ? `?cid=${chanelId}` : '',
      state,
    };
  }

  public static toConference(
    conferenceId: string,
    settings?: {isAudioEnabled: boolean; isVideoEnabled: boolean},
    state?: LocationState,
  ) {
    const search = new URLSearchParams();

    if (settings) {
      const {isAudioEnabled, isVideoEnabled} = settings;

      if (isDefined(isAudioEnabled)) {
        search.set('a', isAudioEnabled ? '1' : '0');
      }

      if (isDefined(isVideoEnabled)) {
        search.set('v', isVideoEnabled ? '1' : '0');
      }
    }

    const searchString = search.toString();

    return {
      pathname: `/conferences/${conferenceId}`,
      search: searchString ? `?${searchString}` : '',
      state,
    };
  }

  public static toConnectStripe({from}: {from: string}) {
    return `${PATH_TYPE.connectStripe}?from=${from}`;
  }

  public static toCourse(
    practice: SlugAndId,
    program: SlugAndId,
    courseId: string,
    programId?: string,
    state?: LocationState,
  ) {
    return {
      // pathname: `${this.toProgram(practice, program).pathname}/program`,
      pathname: `${this.toProgram(practice, program).pathname}/${courseId}`,
      search: `?sqid=${courseId}&pid=${programId}`,
      state,
    };
  }

  public static toCourseGoals(
    practice: SlugAndId,
    program: SlugAndId,
    courseId: string,
    state?: LocationState,
  ) {
    return {
      pathname: `${
        this.toProgram(practice, program).pathname
      }/${courseId}/goals`,
      state,
    };
  }

  public static toEventPage(eventId: string, state?: LocationState) {
    return {
      pathname: `/event-page/${eventId}`,
      state,
    };
  }

  public static toFoodDiary(
    params?: {date?: Timestamp},
    state?: FoodDiaryRouterDTO,
  ) {
    const date = params?.date;

    return {
      pathname: PATH_TYPE.foodDiary,
      search: date ? `?date=${date}` : undefined,
      state,
    };
  }

  public static toMealCRUD(
    {date, id, type}: {date: Timestamp; id: string; type: MealType},
    state: MealRouterDTO,
  ) {
    return {
      pathname: PATH_TYPE.foodDiaryMeal,
      search: `?id=${id}&type=${type}&date=${date}`,
      state,
    };
  }

  public static toPractice(practice: SlugAndId, state?: LocationState) {
    return {
      pathname: `/coaches/${this.getSlugOrId(practice)}`,
      state,
    };
  }

  public static toProgram(
    practice: SlugAndId,
    program: SlugAndId,
    state?: LocationState,
  ) {
    return {
      pathname: `${this.toPractice(practice).pathname}/${this.getSlugOrId(
        program,
      )}`,
      state,
      // pathname: `/coaches/${this.getSlugOrId(
      //   practice,
      // )}/programs/${this.getSlugOrId(program)}`,
    };
  }

  public static toProgramFuckTheRouterIHaveDOM(program: SlugAndId): string {
    /** .replace(/\/$/) to prevent slash duplication.
     *  When / was added manually in the end of url.
     *
     *  Issue: {@link https://app.clickup.com/t/263db5t}
     *  */
    const pathname = document.location.pathname.replace(/\/$/, '');

    return `${pathname}/${this.getSlugOrId(program)}`;
    // return `${document.location.pathname}/programs/${this.getSlugOrId(
    //   program,
    // )}`;
  }

  public static toProgramFuckTheRouterIHaveDOMWithSearch(
    program: SlugAndId,
  ): string {
    return `${this.toProgramFuckTheRouterIHaveDOM(program)}${
      window.location.search
    }`;
  }

  public static toTodos({
    courseId,
    state,
    type,
  }: {
    courseId?: Nullable<string>;
    state?: LocationState;
    type?: TypeFilterValue;
  } = {}) {
    const urlSearchParams = new URLSearchParams();

    if (courseId) {
      urlSearchParams.append('cs', courseId);
    }

    if (type) {
      urlSearchParams.append('t', type);
    }

    const search = urlSearchParams.toString();

    return {
      pathname: '/to-dos',
      // TODO: get somehow search key from filter by course
      search: search.length ? `?${search}` : '',
      state,
    };
  }

  public static toUserProfilePage(
    userId: string,
    courseId?: Nullable<string>,
    fromChat = false,
  ) {
    return {
      pathname: `/profile-page/${userId}`,
      search: [
        courseId ? `?cId=${courseId}` : '',
        fromChat ? '&fromChat=true' : '',
      ].join(''),
    };
  }

  public static toYourSpace() {
    return {
      pathname: PATH_TYPE.yourSpace,
    };
  }

  private static getSlugOrId(slugAndId: SlugAndId) {
    return slugAndId.slug || slugAndId.id;
  }
}
